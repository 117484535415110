<template>
    <div class="container" style="background: #F5FFEF" >
      <div class="bottom">
        <img src="../../static/bottomlogo.png" />
      </div>
      <a-layout   id="components-layout-demo-top-side">
        <a-layout-header class="header">
          <loginheader :activekey="5"></loginheader>
        </a-layout-header>
      </a-layout>
      <a-layout-content >
      <div class="produce_content">
      <div class="produce_title">
        <img src="../../static/zc_title.png" />
        <h3>2021-2022年全国中医药文化进校园活动举办</h3>
      </div>
      <div class="produce_picture">
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/shanxitaiyuan.png" />
          </div>
          <h3>山西太原</h3>
          <p>山西省太原市
        东华门小学的同学们展示
        亲手制作的饺子
        </p>
        </div>
        <div class="proitem">
          
          <div class="pic">
            <img  src="../../static/zhejianglishui.png" />
          </div>
          <h3>浙江丽水</h3>
          <p>浙江省丽水市遂昌县金竹小学
学生体验中药眼贴
学习中医药护眼知识

        </p>
        </div>
        <div class="proitem">
          
          <div class="pic">
            <img  src="../../static/shandongjining.png" />
          </div>
          <h3>山东济宁</h3>
          <p>山东省济宁市任城实验小学学生分组认真制作
中医药脸谱并展示成果
        </p>
        </div>
        <div class="proitem">
          
          <div class="pic">
            <img  src="../../static/anhuihefei.png" />
          </div>
          <h3>安徽合肥</h3>
          <p>安徽省合肥市幸福路小学学生
在活动中跟随安徽中医药大学的
老师练习传统的五禽戏、易筋经等中医养生功法

        </p>
        </div>
        <div class="proitem">
          
          <div class="pic">
            <img  src="../../static/hunanshengxianggangyizhong.png" />
          </div>
          <h3>湖南省</h3>
          <p>湖南省湘钢一中教育集团
十二中校区学生在中医专家指导下制作了根据中医的时间养生智慧和食药同源思想并结合湘潭的气候以及饮食特点设计而成的鲜人参饺子

        </p>
        </div>
        <div class="proitem">
          
          <div class="pic">
            <img  src="../../static/henannanyang.png" />
          </div>
          <h3>河南南阳</h3>
          <p>河南省南阳市
第十四小学组织中医社团成员
来到医圣祠参观

        </p>
        </div>
        <div class="proitem">
          
          <div class="pic">
            <img  src="../../static/jiangsunanjing.png" />
          </div>
          <h3>江苏南京</h3>
          <p>江苏省南京市
第五初级中学的同学们
跟着专家讲解学习按摩耳穴

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/doujiangyan.png" />
          </div>
          <h3>都江堰</h3>
          <p>都江堰青城小学
“中医药文化进校园”课程体系
以体验实践为主
让孩子们真正感受到
中医药文化的博大精深
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/sichuanchengdou.png" />
          </div>
          <h3>四川成都</h3>
          <p>成都市双流区棠湖中学
针对高三学子开展
“让我们一起陪伴青少年健康成长”主题讲座，受到师生们的热烈欢迎。
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/sichuan.png" />
          </div>
          <h3>四川</h3>
          <p>温江区东大街第一小学北校区
带领同学们到学校“百草园”现场辨认中草药，和同学们进行“种药”活动，种下一颗颗中药种子，建设属于他们的小小药园

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/junlianxianfenglexiang.png" />
          </div>
          <h3>四川</h3>
          <p>筠连县丰乐乡
龙镇小学开展了以“弘扬中医药文化 呵护生命健康”为主题的“中医药文化进
校园”活动
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/sichuanshengkexuechengdiyizhong.png" />
          </div>
          <h3>四川</h3>
          <p>四川省科学城第一中学
四川中医药高等专科学校
通过开设中医药相关课程
体验活动、志愿服务等方式
增强了广大师生对中医药优秀文化的了解与认同
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/meishanpengshanqujiangkouxiaoxue.png" />
          </div>
          <h3>四川</h3>
          <p>眉   山   彭山区江口小学
来自彭山区中医医院药剂科副主任中药师邬堰分别以神农的传说、伏羲的传说、黄帝的传说等故事，向同学们讲解中医药知识，将带来的中草药让学生通过一看、二闻、三尝来认识我们常见的草药。
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/leshaneshanxiaoxuexuao.png" />
          </div>
          <h3>四川</h3>
          <p>乐  山
峨山小学校
让学生种植中草药、参观中草药植物园和中草药展览厅、做香囊等，使学生对中医药文化有了一定的
感受和认识。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/sichuanguangan.png" />
          </div>
          <h3>四川</h3>
          <p>广   安     顾县小学
体育课已经开设八段锦课程，体育老师郭俊杰表示，通过练习八段锦，可以让孩子们加强血液循环，对于消化系统，呼吸系统，运动器官可以很好调节，这样的课程，促进同学们的健康成长


        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/hebeilangfang.png" />
          </div>
          <h3>河北廊坊</h3>
          <p>河北省廊坊市
第五小学学生
在该校国医馆内
跟随老师识别中药

        </p>
        </div>
     
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/hebeishijiazhuang.png" />
          </div>
          <h3>河北石家庄</h3>
          <p>举办“传承中医药文化 
争做校园小神农”
石家庄市中小学生中医药文化知识竞赛，让孩子们传承中医药文化.
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/shandongyantai.png" />
          </div>
          <h3>山东烟台</h3>
          <p>烟台黄渤海新区八角第二小学“小中医”传承大国粹 
山东省中医药文化进校园活动正式启动 

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/hunanhulouxingquweijianjuzuzhikaizhan.png" />
          </div>
          <h3>湖南</h3>
          <p>娄星区卫健局组织开展“中医药文化进校园”活动，衔接双江乡卫生院、双江中学，积极开展“中医药文化进校园”系列活动。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/guangxi.png" />
          </div>
          <h3>广西</h3>
          <p>传承中医国粹 
广西中医药文化进校园
品牌活动举行 
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/hunanhengyang.png" />
          </div>
          <h3>湖南衡阳</h3>
          <p>衡阳市石鼓区人民街道社区卫生服务中心联合人民路小学开展“中医健康保健”专题讲座。
讲解“中医从哪儿来”“中医怎么看病的”“中药的分类食物的性味”等几个方面，为学生带来了一场精彩的中医药文化知识盛宴。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/guangxi.png" />
          </div>
          <h3>广西</h3>
          <p>广西国际壮医医院到南湖小学开展“中医药文化进校园”结对共建活动。为师生们提供把脉、壮医敷贴、健康咨询等服务

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/shangdongdezhou.png" />
          </div>
          <h3>山东德州</h3>
          <p>平原开展“中医药文化进校园，国粹经典伴成长”活动

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/jiangxisheng.png" />
          </div>
          <h3>江西省</h3>
          <p>广丰区开展
“中医药文化”进校园活动

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/hunanxiangtan.png" />
          </div>
          <h3>湖南湘潭</h3>
          <p>湘潭医卫职院举办“薪火传承”中医药文化进校园活动，让医卫学子更多地学习中医、了解中医、感受中医、认同中医，树立崇尚中医、珍爱生命的理念，弘扬国医精髓。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/fujian.png" />
          </div>
          <h3>福建</h3>
          <p>福建中医药大学附属人民医院主任中药师何丽君为台四小学校园艺社团的孩子们上了一堂生动的常见中药知识课——《神奇有魅力的中草药》。何主任用浅显易懂的语句，介绍中草药的文化、特性、功效、使用渊源以及如何进行中药治疗，让孩子们更加深刻地认识到中草药“药食同源”的特性。 
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/guangdong.png" />
          </div>
          <h3>广东</h3>
          <p>广东省第二中医院与中星小学开展结对共建，双方围绕推进“中医药文化进校园”纵深发展进行了积极探索，先后举办了以中医体质辨识、中医特色疗法为主的健康体验活动和以弘扬岐黄文化、普及“肌骨同治”健康理论的健康讲座，受到广大师生、家长的关注和好评。 
 
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/yunnan.png" />
          </div>
          <h3>云南</h3>
          <p>昆明市中华小学经开学校“神奇的中草药 中医药文化走进小学课堂”实践活动 。

这次参观学习不仅开阔了同学们的眼见，加深了职业体验，还让同学们认识了多种中草药及其特性，感受中医的博大精深。
 
 
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/zhongqing.png" />
          </div>
          <h3>重庆</h3>
          <p>10所学校入选“重庆市首批中医药文化进校园示范学校”。

依托独特的地理位置及厚重的文化积淀，多渠道、全方位地普及中医药文化知识，培养学生兴趣爱好，提升其传统文化素养。
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/shanghai.png" />
          </div>
          <h3>上海</h3>
          <p>江帆中学开设“中医药文化进校园”特色课程，开办“神农百草”社团以及“八段锦”社团，通过网课的方式教授孩子们学中医、识草药、练功法，吸引了不少学生报名参加。
        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/helongjiang.png" />
          </div>
          <h3>黑龙江</h3>
          <p>为积极响应中医中药中国行——黑龙江省中医药健康文化推进行动，学校组织师生开展中医药健康文化知识大赛、中医药健康文化精品遴选、中医中药进校园、中医药文化科普巡讲、中医药健康文化素养调查等活动，促进中医药文化建设，营造中医药事业发展的良好氛围。 

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/liaoning.png" />
          </div>
          <h3>辽宁</h3>
          <p>中药学院程海涛老师在沈阳世博园药草园为来自沈阳市不同学校的中小学生们开展了一场别开生面的中医药文化科普活动。孩子们通过观、摸、闻、尝等形式感受和认识药用植物. 

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/jining.png" />
          </div>
          <h3>吉林</h3>
          <p>吉首市第一小学从今年开始，就把普及中医药文化知识作为提升学生国学素养和健康水平的一项重要教育任务，认真制定了“中医药文化进校园，扬国粹经典伴成长”教育活动方案。


        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/hubei.png" />
          </div>
          <h3>湖北</h3>
          <p>洪山区第一小学书城路分校为中医药进校园试点学校。该校开发优秀传统文化校本课程，在校园里开辟了一片“百草园”，由科学课教师与学生一起种艾草等中草药类植物，形成“种、培、收、制”全过程的中医药文化链条。



        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/shanxiyanan.png" />
          </div>
          <h3>陕西</h3>
          <p>北京大学第三医院延安分院（延安市中医医院）护理团队分别走进延安桥儿沟深桥红军小学和延安市新区第二小学，开展“5.12”国际护士节系列之中医药文化进校园活动，为老师和学生进行中医养生功法教学、感受和体验中医药文化，近距离感受中医中药的独特魅力。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/hunanchangshashi.png" />
          </div>
          <h3>湖南</h3>
          <p>2023年2月28日下午，长沙市天心区大托铺街道社区卫生服务中心在黄鹤小学开展中医药文化进校园活动。带着孩子们学习中医药知识、示范穴位养生、讲解香囊传承，在小小少年们心中种下一颗国粹的种子。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/neimeng.png" />
          </div>
          <h3>内蒙</h3>
          <p>培养中小学生对中华传统文化的热爱，近日，自治区国际蒙医医院走进呼和浩特市蒙古族学校，开展中医药（蒙医药）文化知识进校园活动。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/gansu.png" />
          </div>
          <h3>甘肃</h3>
          <p>红山根小学开展“弘扬国学精髓  传承中医文化”中医药文化进小学校园科普主题讲座。

        </p>
        </div>
        <div class="proitem">
          <div class="pic">
            <img  src="../../static/guizhou.png" />
          </div>
          <h3>贵州
</h3>
          <p>为传承弘扬中医药文化，推进中医药文化内涵建设。松桃自治县民族中医院联合该县第六完小开展“打造百草园•新增风景线”中草药种植主题活动，中药材社团30余名师生在医护人员指导下有序移栽中草药。

        </p>
        </div>
    
      </div>
      </div>
      </a-layout-content>
  
      <a-layout-footer style="padding: 0;bottom: 0;width: 100%;">
        <loginfooter></loginfooter>
      </a-layout-footer>
    </div>
  
  </template>
  
  <script>
  import loginheader from "../home/components/loginheader";
  import loginfooter from "../home/components/loginfooter";
  export default {
    name: "companyproduce",
    components:{
      loginheader:loginheader,
      loginfooter:loginfooter
    },
    data(){
      return {
  
       
      }
    },
    methods:{
        handleScroll(){
          
        
        }
    },
    mounted(){
        　window.addEventListener('scroll',this.handleScroll) 
    }
  }
  </script>
  <style>
  @media (min-width: 1024px){
    body,html{font-size: 18px!important;}
  }
  @media (min-width: 1100px) {
    body,html{font-size: 20px!important;}
  }
  @media (min-width: 1280px) {
    body,html{font-size: 22px!important;}
  }
  @media (min-width: 1366px) {
    body,html{font-size: 24px!important;}
  }
  @media (min-width: 1440px) {
    body,html{font-size: 25px!important;}
  }
  @media (min-width: 1680px) {
    body,html{font-size: 28px!important;}
  }
  @media (min-width: 1920px) {
    body,html{font-size: 33px!important;}
  }
  </style>
  <style scoped lang="less">
  #components-layout-demo-top-side .header{
    height: 2.76rem;
    background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);
    box-sizing: border-box;
  }
  .produce_content{
    margin: 0 auto;
    margin-top:33px;
    width: 1399px;

background: #FFFFFF;
box-shadow: 0px 0px 18px 0px rgba(8,68,31,0.26);
border-radius: 20px;
.produce_title{
  width: 500px;
  height: 120px;
  margin-left: 18%;
  position: relative;
  h3{
   position:absolute;
   padding:15px 10px 5px 65px;
   top:0;
   font-size: 36px;
font-family: Source Han Sans SC;
font-weight: 500;
white-space: nowrap;
color: #FFFFFF;
    }
   
}
.produce_picture{
  margin-left: 3%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
.proitem{
  background: #FFFFFF;
box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.19);
  margin-right: 2%;
  margin-bottom: 2%;
  width: 423px;
  height: 461px;
  display: flex;
  flex-direction: column;
  .pic{
    width: 100%;
    height: 265px;
    img{
        width: 100%;
        height: 100%;
    }
  }
  h3{
 margin-top: 5px;
    width: 129px;
height: 21px;
font-size: 18px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #151515;
line-height: 32px;
padding-left: 15px;
  }
  p{
  
    width: 394px;margin: 0;

font-size: 18px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #151515;
line-height: 26px;
padding-left:15px;

          
  }

}
}
  }
  .bottom{
    padding: 0;

    z-index: 999;
    position: fixed;
    width: 10rem;
  
    bottom:0;
    right: 0;
    width:11.8rem;
    img{
      width: 100%;
    }
  
  }
  </style>